import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { COLOR_BLACK } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import { buttonStyles, fontStyles } from '../constants/styles';
import { isPrepayRate } from '../helpers/booking';

import Arrow from './Arrow';
import FormattedPrice from './FormattedPrice';
import Policy from './Policy';


const RateOverview = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  ${fontStyles.body}
  cursor: pointer;

  > span:first-child {
    flex: 0 0 100%;

    @media ${breakpoints.l} {
      flex-basis: 50%;
    }
  }
`;

const Price = styled.span`
  margin-right: 2em;

  span:first-child {
    ${fontStyles.body}
  }

  span:last-child {
    ${fontStyles.bodySmall}
  }

  @media ${breakpoints.l} {
    margin-left: auto;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
  background-color: ${COLOR_BLACK};
  color: ${(props) => props.color};
`;

const TermsAndConditions = styled.small`
  width: 100%;
  ${fontStyles.bodySmall}
`;

const TermsAndConditionsLabel = styled.span`
  position: relative;
  padding-right: 1em;
`;

const DisclosureArrow = styled(Arrow)`
  position: absolute;
  top: 1em;
  right: 0;
  transform: rotate(90deg);

  span {
    &:first-child,
    &:nth-child(2) {
      ${(props) => (props.isOpen ? 'left: -.8rem' : 'right: 0')};
      width: .35rem;
      transform-origin: ${(props) => (props.isOpen ? 'left' : 'right')};
      transition-property: transform, transform-origin;
      transition-duration: .5s;
    }

    &:first-child {
      transform: rotate(${(props) => (props.isOpen ? '-45deg' : '45deg')});
    }

    &:nth-child(2) {
      transform: rotate(${(props) => (props.isOpen ? '45deg' : '-45deg')});
    }
  }
`;

const RateDetails = styled.div`
  ${({ isOpen }) => !isOpen && 'display: none;'}

  padding-bottom: 2em;
  ${fontStyles.legal}

  @media ${breakpoints.m} {
    width: 50%;
  }
`;


class Rate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBooking: false,
      isOpen: false,
    };

    this.handleBook = this.handleBook.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleBook(event) {
    event.stopPropagation();
    this.setState({
      isBooking: true,
    });
    const { ratePlan, roomType } = event.currentTarget.dataset;
    this.props.handleChooseStay(ratePlan, roomType);
  }

  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { color, roomType, stay } = this.props;
    const { isBooking, isOpen } = this.state;

    return (
      <Fragment>
        <RateOverview aria-controls={stay.ratePlan} aria-expanded={isOpen} onClick={this.toggle}>
          <span>{stay.ratePlanName}</span>
          <Price>
            <FormattedPrice value={stay.total / stay.duration} />&nbsp;<span>/&nbsp;<FormattedMessage id="booking.perNight" /></span>
          </Price>
          <StyledButton
            color={color}
            data-rate-plan={stay.ratePlan}
            data-room-type={roomType}
            onClick={this.handleBook}
          ><FormattedMessage id={isBooking ? 'booking.bookingInProgress' : 'booking.bookNow'} />
          </StyledButton>
          <TermsAndConditions isOpen={isOpen}><TermsAndConditionsLabel><FormattedMessage id="booking.termsConditions" /><DisclosureArrow isOpen={isOpen} /></TermsAndConditionsLabel></TermsAndConditions>
        </RateOverview>
        <RateDetails id={stay.ratePlan} isOpen={isOpen}>
          <If condition={stay.policies.cancel}>
            <Policy name={<FormattedMessage id="booking.cancellation">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
              <For each="cancellationPolicy" index="idx" of={stay.policies.cancel}>
                <div key={`${stay.ratePlan}-cancellation-${idx}`} dangerouslySetInnerHTML={{ __html: cancellationPolicy.desc }} />
              </For>
            </Policy>
          </If>
          <If condition={stay.policies.guarantee}>
            <Policy name={<FormattedMessage id={isPrepayRate(stay.policies.guarantee.code) ? 'booking.payment' : 'booking.guarantee'}>{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
              <div dangerouslySetInnerHTML={{ __html: stay.policies.guarantee.desc }} />
            </Policy>
          </If>
          <If condition={stay.taxes}>
            <Policy name={<FormattedMessage id="booking.taxes">{(txt) => (<span>{txt.toLowerCase()}</span>)}</FormattedMessage>}>
              <For each="tax" index="idx" of={stay.taxes}>
                <div key={`${stay.ratePlan}-taxes-${idx}`} dangerouslySetInnerHTML={{ __html: tax.desc }} />
              </For>
            </Policy>
          </If>
        </RateDetails>
      </Fragment>
    );
  }
}

Rate.propTypes = {
  color: PropTypes.string.isRequired,
  handleChooseStay: PropTypes.func.isRequired,
  roomType: PropTypes.string.isRequired,
  stay: PropTypes.object.isRequired,
};

export default Rate;
