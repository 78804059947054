import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import { SELECT_STAY } from '../state/actionTypes';
import { COLOR_BLACK, COLOR_ROOMS_RED } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import { SPACING_VERTICAL } from '../constants/spacings';
import {
  bulletStyles, buttonBorderWidth, fontStyles, verticalButtonPadding,
  verticalButtonPaddingLarge,
} from '../constants/styles';
import { customMarkdown } from '../helpers/markdown';

import BookingWidget from '../components/BookingWidget';
import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import ModuleWrapper from '../components/ModuleWrapper';
import PageTitle from '../components/PageTitle';
import Rate from '../components/Rate';

import RoomTeaser from '../components/RoomTeaser';

import { RoomList } from './rooms';


const StyledWrapper = styled.div`
  margin-bottom: 5rem; /* 80/16 */

  border-top: 1px solid ${COLOR_BLACK};
  border-bottom: 1px solid ${COLOR_BLACK};
  ${fontStyles.bodySmall}
`;

const RoomDetails = styled.section`
  position: relative;
  left: 0;
  width: 100%;
  ${SPACING_VERTICAL.s};
  ${fontStyles.bodySmall}

  ul li {
    ${bulletStyles}
  }
  @media ${breakpoints.l} {
    left: calc(50% + 4vw);
    width: calc(50% - 4vw);
  }
`;

const StatusMessage = styled.p`
  padding: calc(1em + ${buttonBorderWidth} + ${verticalButtonPadding}) 0;

  @media ${breakpoints.l} {
    padding: calc(1em + ${buttonBorderWidth} + ${verticalButtonPaddingLarge}) 0;
  }
`;

const SearchResult = styled.div`
  padding: .75em 0;

  & + & {
    border-top: 1px solid ${COLOR_BLACK};
  }
`;

export const SuggestedRoomsHeading = styled.p`
  ${fontStyles.subtitle}
  margin-bottom: 4rem;
  text-align: center;
`;


class Room extends Component {
  constructor(props) {
    super(props);

    this.handleChooseStay = this.handleChooseStay.bind(this);
  }

  handleChooseStay(ratePlan, roomType) {
    const { chooseStay, intl: { locale } } = this.props;
    chooseStay({
      language: locale,
      ratePlan,
      roomType,
    });
  }

  render() {
    const { data, location, pageContext, stays, searchInitiated, searchInProgress } = this.props;
    const { languageSwitch } = pageContext;

    const roomPage = data.allDatoCmsRoom.edges[0].node;
    const roomsPage = data.allDatoCmsRoomsPage.edges[0].node;
    const footerData = data.allDatoCmsFooter.edges[0].node;

    const {
      body, bookingId, details, featuredImage, name, seoMetaTags, suggestedRooms,
    } = roomPage;

    const staysForRoom = stays[bookingId]
      ? Object.values(stays[bookingId]).sort((a, b) => a.total - b.total)
      : [];

    const suggestedAvailableRooms = suggestedRooms
      .filter((room) => (searchInitiated ? !!stays[room.bookingId] : true))
      .slice(0, 2);

    const gradient = roomPage.gradient.length ? roomPage.gradient : [{ color: { hex: COLOR_ROOMS_RED } }];

    const colorObject = gradient[0].color;
    const buttonColor = colorObject.hex;

    return (
      <Layout hideBookingLink languageSwitch={languageSwitch} location={location}>
        <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
          <HelmetMichelberger seo={seoMetaTags} />
          <PageTitle>{name}</PageTitle>
          <BookingWidget buttonColor={buttonColor} colorObject={colorObject} roomType={bookingId} />
          <div style={{ opacity: searchInProgress ? 0.25 : 1 }}>
            <If condition={featuredImage}>
              <ModuleWrapper isSmall>
                <Img key={featuredImage.id} fluid={featuredImage.fluid} />
              </ModuleWrapper>
            </If>
            <If condition={details}>
              <RoomDetails>{customMarkdown(details)}</RoomDetails>
            </If>
            <StyledWrapper>
              <Choose>
                <When condition={!searchInitiated}>
                  <StatusMessage><FormattedMessage id="booking.roomSearchPrompt" /></StatusMessage>
                </When>
                <When condition={searchInProgress}>
                  <StatusMessage><FormattedMessage id="booking.roomLoading" /></StatusMessage>
                </When>
                <When condition={staysForRoom.length < 1}>
                  <StatusMessage><FormattedMessage id="booking.resultsRoomUnavailable" /></StatusMessage>
                </When>
                <Otherwise>
                  <For each="stay" of={staysForRoom}>
                    <SearchResult key={stay.ratePlan}>
                      <Rate color={buttonColor} handleChooseStay={this.handleChooseStay} roomType={bookingId} stay={stay} />
                    </SearchResult>
                  </For>
                </Otherwise>
              </Choose>
            </StyledWrapper>
            <For each="block" index="index" of={body}>
              <BlockRouter key={block.id} block={block} index={index} />
            </For>
            <If condition={suggestedAvailableRooms.length}>
              <SuggestedRoomsHeading>
                <FormattedMessage defaultMessage="You might also like" id="global.suggestedRoomsTitle" />
              </SuggestedRoomsHeading>
            </If>
            <RoomList>
              <For each="room" index="index" of={suggestedAvailableRooms}>
                <RoomTeaser
                  key={`room_${room.id}`}
                  room={room}
                  roomPath={`/${roomsPage.slug}/${room.slug}`}
                  stays={stays[room.bookingId]}
                />
              </For>
            </RoomList>
          </div>
        </GradientWrapper>
      </Layout>
    );
  }
}

Room.propTypes = {
  chooseStay: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  searchInitiated: PropTypes.bool.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  stays: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    availability: { searchInitiated, searchInProgress, stays },
  } = state;
  return {
    searchInitiated,
    searchInProgress,
    stays,
  };
};

const mapDispatchToProps = (dispatch) => ({
  chooseStay: (payload) => dispatch({ type: SELECT_STAY, payload }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Room));

export const query = graphql`
  query RoomBySlug($locale: String!, $slug: String!) {
    allDatoCmsRoom(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
          bookingId
          featuredImage {
            fluid(maxWidth: 2400, maxHeight: 1600, imgixParams: { fm:"jpg", auto:"format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          details
          ...GatsbyDatoCmsRoomBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          suggestedRooms {
            bookingId
            id
            slug
            featuredImage {
              fluid(maxWidth: 2400, maxHeight: 1600, imgixParams: { fm:"jpg", auto:"format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            name
          }
          gradient {
            color {
              hex
            }
          }
        }
      }
    }
    allDatoCmsRoomsPage(filter:{ locale: { eq: $locale } }) {
      edges {
        node {
          slug
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
